import React from 'react';

import classes from './Footer.module.css';

const Footer = () => {
    return (
        <div className = { classes.footerContainer }>
            <span>Copyright © 2021, Všechna práva vyhrazena</span>
        </div>
    );
}

export default Footer;