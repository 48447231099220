import React, { useState, useRef } from 'react';

import Header from './component/Header';
import Logo from './component/Logo';
import Offer from './component/Offer';
import Contact from './component/Contact';
import Gallery from './component/Gallery';
import About from './component/About';
import Footer from './component/Footer';
import Modal from './component/Modal';

import './App.css';


function App() {

  const [modalData, setModalData] = useState({
    show:false,
    imgId:"",
    title:""
  });

  const logoRef = useRef();
  const offerRef = useRef();
  const contactRef = useRef();
  const galleryRef = useRef();
  const aboutRef = useRef();

  return (
    <div className="App" align="center">
      <div className="Container">
        <Header logoRef = {logoRef}
            offerRef = {offerRef} 
            contactRef = {contactRef} 
            galleryRef = {galleryRef} 
            aboutRef = {aboutRef } />
        <Logo logoRef = {logoRef} />
        <Offer offerRef = {offerRef} setModalData = { setModalData } />
        <Contact  contactRef = {contactRef} />
        <Gallery galleryRef = {galleryRef} setModalData = { setModalData } />
        <About aboutRef = {aboutRef } />
        <Footer />
        { modalData.show &&
          <Modal modalData = { modalData }
              setModalData = { setModalData } />
        }
      </div>
    </div>
  );
}
export default App;
/*
function showModal(imgId, title) {
  const modal = document.getElementById("modal-container");
  const modalImg = document.getElementById("curr-modal");
  modalImg.src = imgId;
  modal.style.display = "block";
  const span = document.getElementsByClassName("close")[0];
  span.onclick = function () {
    modal.style.display = "none";
  };
  modal.onclick = function () {
    modal.style.display = "none";
  };
  setModalTitle(title);
}*/