import React, { useEffect, useState } from "react";
import * as R from 'ramda'

import GDImageViewer from '../viewer/GDImageViewer';

import classes from './Gallery.module.css';

const Gallery = (props) => {
    const [dirItems, setDirItems] = useState([]);
    const [currentDir, setCurrentDir] = useState(null);
    const [currentImg, setCurrentImg] = useState({
        show: false,
        imgId: "",
        title: ""
    });
    const [imgIds, setImgIds] = useState([]);
    
    
    const GOOGLE_DRIVE_IMG_URL = "http://drive.google.com/uc?export=view&id=";
    const GOOGLE_API_KEY = "AIzaSyDDvohRQRPkQmQaBOW6fBuPVHopkNvd5qU";
    const GOOGLE_DRIVE_URL_START = "https://www.googleapis.com/drive/v2/files?q=%27";
    const GOOGLE_DRIVE_URL_END = "%27+in+parents&key=";
    const GOOGLE_DRIVE_GALLERY_DIR_ID = "1MOTzh6KceWzR2SkT-VUQt__QIIZLlbcA";

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        await fetch(
            GOOGLE_DRIVE_URL_START +
            GOOGLE_DRIVE_GALLERY_DIR_ID +
            GOOGLE_DRIVE_URL_END +
            GOOGLE_API_KEY
        )
        .then(response => response.json())
        .then(jsonResp => {   
            setDirItems(jsonResp.items);
            if(!R.isEmpty(jsonResp.items)) {
                setCurrentDir({
                    id: jsonResp.items[0].id,
                    title: jsonResp.items[0].title
                })
            }
        });
    }

    const setLeftNeighborPhoto = (currentImgId) => {
        let i = 0;
        for(const imgId of imgIds) {
            if((GOOGLE_DRIVE_IMG_URL + imgId.id) === currentImgId) {
                if(i > 0) {
                    setCurrentImg({show:true, imgId:GOOGLE_DRIVE_IMG_URL + imgIds[i-1].id, title:imgIds[i-1].title});
                }
            }
            i++;
        }
    }

    const setRightNeighborPhoto = (currentImgId) => {
        let i = 0;
        for(const imgId of imgIds) {
            if((GOOGLE_DRIVE_IMG_URL + imgId.id) === currentImgId) {
                if(i < imgIds.length - 1) {
                    setCurrentImg({show:true, imgId:GOOGLE_DRIVE_IMG_URL + imgIds[i+1].id, title:imgIds[i+1].title});
                }
            }
            i++;
        }
    }

    function checkFormat(url) {
        return true;
    }

    return (
        <div ref = {props.galleryRef}  className = { classes.galleryContainer }>
            <h2>Galerie</h2>
            <div className = { classes.photoContainer }>
                <div className = { classes.buttonContainer }>
                { dirItems &&
                    dirItems.map((item, i) => {
                        const title = R.propOr("", "title", item)
                        if (checkFormat(title)) {
                            const id = R.propOr("", "id", item)
                            return (<button type="button" onClick = { () => setCurrentDir({ id:id, title:title }) }>{ title }</button>);
                        }
                        return (<noscript />);
                    }) 
                }
                </div>
                { currentDir &&
                    <div>                        
                        <div className= { classes.currentPhoto }>
                            <div onClick={() => { setLeftNeighborPhoto(currentImg.imgId) }} className = {classes.arrowLeft}><span>&#10094;</span></div>
                            <img onClick={() => { props.setModalData({show:true, imgId:currentImg.imgId, title:currentImg.title}); }}
                                    height = "100%"
                                    className = { " gd-pointer " } 
                                    src = { currentImg.imgId } 
                                    alt = { currentImg.title } />
                            <div onClick={() => { setRightNeighborPhoto(currentImg.imgId) }} className = {classes.arrowRight}><span>&#10095;</span></div>
                        </div>
            
                        
                        <GDImageViewer setImgData = { setCurrentImg } 
                            imgIds = { imgIds }
                            setImgIds = {setImgIds}
                            loadFirst={true}
                            data = { {
                                gkey: GOOGLE_API_KEY,
                                dirId: currentDir.id,
                                header:currentDir.title
                            } }
                            width = "100px"
                            height = "auto" />
                    </div>
                }
            </div>
        </div>
    );
}

export default Gallery;