import React from 'react';

import classes from './About.module.css';

const About = (props) => {

    return (
        <div ref = {props.aboutRef} className = { classes.aboutContainer }>
            <h2>O nás</h2>
            <div className= {classes.article}>
            <span>Někdy v létě roku 2011 v nás definitivně dozrála myšlenka na pořízení tohoto zařízení a stánku, zejména po návštěvě Chorvatska, kdy je skoro u každého domu nějaká zahrádka, stoly a židle. Tato myšlenka pak byla dále rozvíjena a přizpůsobena místním podmínkám a poměrům.</span>
            <br />
            <span>Na internetu byla v té době spousta stánků a podobných zařízení, ale do užšího výběru postoupily dva. Ten, který máme a byl od roku 2004 umístěn před Kauflandem v Jablonci nad Nisou a ještě jeden, který stál před Kauflandem v Táboře, kde však byla neskutečně vysoká cena.</span>
            <br />
            <span>Hned na zařátku jsme totiž zavrhli jednodušší variantu, aby to byl stánek do 25 m2 a nebo to rozjet s přívěsem upraveným na tuto činnost. Na konci prosince 2011 jsme proto vyrazili do Jablonce, kde jsme si po prohlídce a domluvení ceny okamžitě plácli. V průběhu měsíce ledna 2013 byla připravena plocha k postavení buněk, které ke konci ledna 2012 na dvou kamionech dorazily. Tajně jsme doufali, že to stihneme dát do pořádku a stihneme otevřít na jaře 2012. Ale to jsme netušili, co v Karviné obnáší stavební řízení. Nicméně po 22-ti měsících papírové války jsme se konečně dočkali kolaudace a po sestavení všech jídelníčků a hlavně technologických postupů při přípravě našich výrobků, kdy jsme v pozici tzv. pokusných králíků s celou rodinou a známými chuťově doladili složení, ingredience a výrobní postupy hlavně hamburgerů, jsme k 1.11. 2013 konečně otevřeli.</span>
            <br />
            <span>Každý začátek je těžký, zejména, když se s něčím takovým začíná na zimu, kdy jsou vysoké náklady zejména na topení a je menší pohyb lidí, ale myslím, že jsme si už získali svoje zákazníky, kteří se k nám rádi vracejí, protože jsme vsadili na kvalitu a ne na kvantitu prodaných výrobků.</span>
            <br />
            <span>Neustále se snažíme něco vylepšovat a doplňovat sortiment. Od nového roku máme v nabídce podobné výrobky jako má i nejmenovaný zahraniční řetezec, zabývající se zpracováním zejména kuřecího masa.</span>
            <br />
            <span>Hned zpočátku jsme to zkusili s Fornetti, ale ke konci roku jsme jejich výrobu a prodej ukončili a spíše se zaměřili na to, k čemu je podobný stánek spíše určen. Na jaře plánujeme klobásy z udírny a venkovní posezení.</span>
            <br />
            <span>Věříme, že budete u nás spokojeni a budete se k nám rádi vracet.</span>
            <br />
            </div>
        </div>
    );
}

export default About;