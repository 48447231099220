import React from 'react';

import logo from '../assets/logo.jpg';

import classes from './Logo.module.css';

const Logo = (props) => {
    return (
        <div ref = {props.logoRef} className = { classes.logoContainer }>
            <img src={logo}  className = { classes.logo } alt="Logo" />
        </div>
    );
}

export default Logo;