import React from 'react';

import classes from './Contact.module.css';

import fblogo from '../assets/fblogo.jpg';
import autoshop from '../assets/autoshop.gif';

const Contact = (props) => {
    return (
        <div ref = {props.contactRef} className = { classes.contactContainer }>
            <div className = { classes.links }>
                <h3>Odkazy</h3>
                <div className = { classes.contactText }>
                    <a href="http://autoshop.xf.cz/" target="_blank" rel="noreferrer"><img src={autoshop} width="160px" alt="Autoshop Karviná" /></a><br />
                    <a href="https://www.facebook.com/ukruhace.cz" target="_blank" rel="noreferrer"><img src={fblogo} width="80px" alt="U kruháče facebook page" /></a><br />
                </div>
            </div>
            <div className = { classes.opening }>
                <h3>Otevírací doba</h3>
                <div className = { classes.contactText }>
                    <span>Pondělí - 14:00 až 20:00</span><br />
                    <span>Úterý - 14:00 až 20:00</span><br />
                    <span>Středa - 14:00 až 20:00</span><br />
                    <span>Čtvrtek - 14:00 až 20:00</span><br />
                    <span>Pátek - 14:00 až 21:00</span><br />
                    <span>Sobota - 14:00 až 21:00</span><br />
                    <span>Neděle - 14:00 až 20:00</span><br />
                </div>
            </div>
            <div className = { classes.contacts }>
                <h3>Kontakt</h3>
                <div className = { classes.contactText }>
                    <span>Adresa : Karviná 7, ul. Žižkova 221/52</span><br />
                    <span>Telefon : 608 749 475</span><br />
                    <span>Email : fsebela@seznam.cz</span><br />
                </div>
            </div>
        </div>
    );
}

export default Contact;