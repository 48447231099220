import React from 'react';

import classes from './Modal.module.css';

const Modal = (props) => {

    const closeModal = () => {
        props.setModalData({
            show:false,
            title:props.modalData.title,
            imgId:props.modalData.imgId
        });
    }

    return (
        <div onClick = { () => closeModal() } className={classes.modal}>
            <span className={classes.close} onClick = { () => closeModal() }>&times;</span>
            <img className={classes.modalContainer}  onClick = { (e) => e.stopPropagation() } src={props.modalData.imgId} alt={props.modalData.title} />
            <div className={classes.modalTitle}>{props.modalData.title}</div>
        </div>
    );
}

export default Modal;