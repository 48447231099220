import React from 'react';

import classes from './Header.module.css';

const Header = (props) => {
    const scrollTo = (divRef) => {
        divRef.scrollIntoView();
        if (!((window.innerHeight + window.scrollY) >= document.body.offsetHeight)) {
            window.scrollBy(0, -80);
        }
    }
    return (
        <div className = { classes.headerContainer }>
            <h1 onClick = { () => scrollTo(props.logoRef.current) }>Občerstvení U kruháče</h1>
            <div className = { classes.headerAddress }><span>Karviná 7, ul. Žižkova 221/52</span></div>
            <div className = { classes.headerLink }>
                <button type="button" onClick = { () => scrollTo(props.offerRef.current) }>Nabídka</button>
                <button type="button" onClick = { () => scrollTo(props.contactRef.current) }>Kontakt</button>
                <button type="button" onClick = { () => scrollTo(props.galleryRef.current) }>Galerie</button>
                <button type="button" onClick = { () => scrollTo(props.aboutRef.current) }>O nás</button>
            </div>
        </div>
    );
}

export default Header;