import React, { useEffect } from "react";
import * as R from 'ramda'
import "./GDImageViewer.css";

function GDImageViewer(props) {
  const GOOGLE_API_KEY = props.data.gkey;
  const GOOGLE_DRIVE_IMG_URL = "http://drive.google.com/uc?export=view&id=";
  
  useEffect(() => {
    const GOOGLE_DRIVE_URL_START = "https://www.googleapis.com/drive/v2/files?q=%27";
    const GOOGLE_DRIVE_URL_END = "%27+in+parents&key=";
    

    async function loadData() {
        await fetch(
          GOOGLE_DRIVE_URL_START +
          props.data.dirId +
          GOOGLE_DRIVE_URL_END +
          GOOGLE_API_KEY
        )
        .then(response => response.json())
        .then(jsonResp => {
            props.setImgIds(jsonResp.items);
            if(props.loadFirst && !R.isEmpty(jsonResp.items)) {
                props.setImgData({show:true, imgId:GOOGLE_DRIVE_IMG_URL + jsonResp.items[0].id, title:jsonResp.items[0].title});
            }
        });
     }

      loadData();
  }, [props.data.dirId]);

  function checkFormat(url) {
      return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  const renderImages = (item ,i) => {
    return (
        <img className={ "gd-pointer gd-img gd-pointer gd-hover " }
            onClick={() => { props.setImgData({show:true, imgId:GOOGLE_DRIVE_IMG_URL + item.id, title:item.title}); }}
            src = { GOOGLE_DRIVE_IMG_URL + item.id }
            width = { props.width }
            height = { props.height }
            key = { i }
            alt = { item.title } />
    )

  }

  return (
    <div>
      <h3>{props.data.header}</h3>
      { props.imgIds &&
        props.imgIds.map((item, i) => {
          const title = R.propOr("", "title", item)
          if (checkFormat(title)) {
            return(renderImages(item, i));
          }
          return (<div></div>);
        })
      }
    </div>
  );
}

export default GDImageViewer;