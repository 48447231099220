import React from 'react';

import GDTextViewer from '../viewer/GDTextViewer';

import classes from './Offer.module.css';



const Offer = (props) => {
    const GOOGLE_API_KEY = "AIzaSyDDvohRQRPkQmQaBOW6fBuPVHopkNvd5qU";

    return (
        <div ref = {props.offerRef} className = { classes.offerContainer }>
            <h2>Nabídka</h2>
            <div className = {classes.offerTab}>
                <GDTextViewer data={ {
                        gkey: GOOGLE_API_KEY,
                        jsonId: "1UwaddbcIFHYzgW6FqEa4l6UG4IwmJCrU",
                } }   />     
            </div>
            

        </div>
    );
}

export default Offer;