import React, { useEffect, useState } from "react";
import classes from '../component/Offer.module.css';
import backupData from './nabidka.json';

function GDTextViewer(props) {
    const GOOGLE_API_KEY = props.data.gkey;
    const GOOGLE_DRIVE_URL_START = "https://www.googleapis.com/drive/v3/files/";
    const GOOGLE_DRIVE_URL_END = "?alt=media&key=";
    const [gdJson, setGdJson] = useState([]);
    const [currentTab, setCurrentTab] = useState({ title:"",content:[] });
    const [currentIndex, setCurrentIndex] = useState(0);

    const setTab = (offer, index) => {
        setCurrentTab(offer);
        setCurrentIndex(index);
    }

    useEffect(() => {
        async function loadData() {
            /*
            await fetch(
              GOOGLE_DRIVE_URL_START +
              props.data.jsonId +
              GOOGLE_DRIVE_URL_END +
              GOOGLE_API_KEY
            )
            .then(response => response.json())
            .then(jsonResp => {
                alert(JSON.stringify(jsonResp));
                setGdJson(jsonResp);
                setTab(jsonResp[0],0);    
            });*/
            setGdJson(backupData);
            //setTab(backupData[0],0);
        }

        loadData();
    }, [props.data.jsonId]);
  
/*
    Object.keys(gdJson).map(function(keyName, keyIndex) {
        if(keyIndex===0) {
            //alert(gdJson[keyName]);
        }
    })
*/
    return (
        
                            
        <div>
            <div align="left">
                {
                    gdJson.map((offer, index) => <button type="button" onClick = { () => setTab(offer, index) }>{ offer.title }</button>)
                }  
            </div>
            {  currentTab &&
            <div>
                <div><h3>{currentTab.title}</h3></div>
                    <div className = {classes.currentOfferTab}>
                        <div className = {classes.arrowLeft}>
                            { currentIndex > 0 &&
                                <div onClick={() => { setTab(gdJson[currentIndex-1], currentIndex-1) }}><span>&#10094;</span></div>
                            }
                        </div>
                        <div className = {classes.currentOffersList}>
                            {
                                currentTab.content.map(offerContent => <div className = {classes.currentOffer}><div className = {classes.offerName}>{offerContent.name}</div><div className = {classes.offerPrice}>{offerContent.price} Kč</div></div>)
                            }
                        </div>
                        <div className = {classes.arrowRight}>
                        { currentIndex < (gdJson.length - 1) &&
                            <div onClick={() => { setTab(gdJson[currentIndex+1], currentIndex+1) }}><span>&#10095;</span></div>
                        }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default GDTextViewer;